import { Link } from "@mui/material";

import Twitter from "../../assets/images/footer/twitter.svg";
import Discord from "../../assets/images/footer/discord.svg";
import Github from "../../assets/images/footer/github.svg";
import Medium from "../../assets/images/footer/medium.svg";
import Telegram from "../../assets/images/footer/telegram.svg";
import FooterBg from "../../assets/images/footer_bg.svg";
import styled from "styled-components";

const StyledFooterContainer = styled.div`
  margin-top: 80px;
  width: 100vw;
  height: 290px;
  background-image: url(${FooterBg});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
`;

const StyledFooterLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  padding: 170px 0 30px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
`;

const StyledFooterDocs = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
`;

const StyledLink = styled(Link)`
  color: rgba(255, 255, 255, 0.5) !important;
  text-decoration: none !important;
`;

export default function Footer() {
  return (
    <StyledFooterContainer className="footer">
      <StyledFooterLinks>
        <div className="">
          <StyledLink href="" target={"_blank"} className="">
            <img src={Github} alt="Github" className="" />
          </StyledLink>
        </div>
        <div className="">
          <StyledLink
            href="https://twitter.com/DYNA_Finance "
            target={"_blank"}
            className=""
          >
            <img src={Twitter} alt="Twitter" className="" />
          </StyledLink>
        </div>
        <div className="">
          <StyledLink
            href="https://discord.gg/dynafinance"
            target={"_blank"}
            className=""
          >
            <img src={Discord} alt="Discord" className="" />
          </StyledLink>
        </div>
        <div className="">
          <StyledLink
            href="https://t.me/DYNA_Finance "
            target={"_blank"}
            className=""
          >
            <img src={Telegram} alt="Telegram" className="" />
          </StyledLink>
        </div>
        <div className="">
          <StyledLink href="" target={"_blank"} className="">
            <img src={Medium} alt="Medium" className="" />
          </StyledLink>
        </div>
      </StyledFooterLinks>
      <StyledFooterDocs>
        <div className="">
          <StyledLink
            href="https://docs.dyna.finance/"
            target={"_blank"}
            className=""
          >
            <>Documentation</>
          </StyledLink>
        </div>
        <div className="">
          <StyledLink href="" target={"_blank"} className="">
            <>Github</>
          </StyledLink>
        </div>
        <div className="">
          <StyledLink href="" target={"_blank"} className="">
            <>Media kit</>
          </StyledLink>
        </div>
      </StyledFooterDocs>
    </StyledFooterContainer>
  );
}
