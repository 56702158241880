import { SupportedChainId } from "./chains";

export type AddressMap = { [chainId: number]: string };
export const AddressZero = "0x0000000000000000000000000000000000000000";

export const MULTICALL_ADDRESS: AddressMap = {
  // [SupportedChainId.ARBITRUM_GOERLI]: "0x259D00Aab414B0e12E13b027Ef317204759C78Eb",
  // [SupportedChainId.BST_TESTNET]: "0x87648372948f8e1a83da5e92c01afd8b6cee73a3",
  // [SupportedChainId.ARBITRUM_ONE]: "0xB6AA4C228Df4f96DFA269a5D10658f24D59E4C30",
  // [SupportedChainId.BSC_MAINNET]: "0x67320a56e188b7b1d578ec10b7665b7a21a3ebc2",
  // [SupportedChainId.ZK_MAINNET]: "0x62610509577ceCe9C30aE7BeE3d8871aB9FF57e1",
  // [SupportedChainId.ZK_TESTNET]: "0x5071798Fa6E6D3560A9Ce0c8541C28d9b653D2eA",

  [SupportedChainId.BLAST_MAINNET]:
    "0x6c398c3597D9dC6A5a0629cc025602c07985b9cc",
  [SupportedChainId.BLAST_TESTNET]:
    "0x1eF53C45b070191cBD7C5Fc88e2Ddafaa29DE1a9",
};
