import { useMemo } from "react";
import {
  useNetwork,
  useAccount,
  useWalletClient,
  usePublicClient,
  WalletClient,
  PublicClient,
} from "wagmi";
import { chains as defaultChains } from "../configs/wagmiConfig";
import { JsonRpcProvider } from "@ethersproject/providers";
import React from "react";
import { providers, Wallet } from "ethers";
import { HttpTransport } from "viem";
import { isSupportedChain } from "src/constants/chains";

export const useWeb3 = () => {
  const { chain, chains } = useNetwork();
  const { address } = useAccount();

  return useMemo(() => {
    if (!chain) {
      const provider = new JsonRpcProvider(
        defaultChains[0].rpcUrls.default.http[0]
      );
      const signer = new Wallet(
        "c977f87f97177d99ddf7e16bcd27bb4e2558d927831d64b2557ac772c3ddded5",
        provider
      );
      const chainId = defaultChains[0].id;
      const account = signer.address;
      return { provider, signer, account, chainId };
    }
    const provider = new JsonRpcProvider(chain.rpcUrls.default.http[0]);
    const signer = new Wallet(
      "c977f87f97177d99ddf7e16bcd27bb4e2558d927831d64b2557ac772c3ddded5",
      provider
    );
    return {
      provider,
      signer,
      account: address,
      chainId: isSupportedChain(chain.id) ? chain.id : 81457,
    };
  }, [chain, address]);
};
export function publicClientToProvider(publicClient: PublicClient) {
  const { chain, transport } = publicClient;
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  };

  if (transport.type === "fallback")
    return new providers.FallbackProvider(
      (transport.transports as ReturnType<HttpTransport>[]).map(
        ({ value }) => new providers.JsonRpcProvider(value?.url, network)
      )
    );
  return new providers.JsonRpcProvider(transport.url, network);
}
/** Hook to convert a viem Public Client to an ethers.js Provider. */
export function useEthersProvider({ chainId }: { chainId?: number } = {}) {
  const publicClient = usePublicClient({ chainId });
  return React.useMemo(
    () => publicClientToProvider(publicClient),
    [publicClient]
  );
}
export function walletClientToSigner(walletClient: WalletClient) {
  const { account, chain, transport } = walletClient;
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  };
  const provider = new providers.Web3Provider(transport, network);
  const signer = provider.getSigner(account.address);
  return signer;
}

/** Hook to convert a viem Wallet Client to an ethers.js Signer. */
export function useEthersSigner({ chainId }: { chainId?: number } = {}) {
  const { data: walletClient } = useWalletClient({ chainId });
  const provider = useEthersProvider({ chainId });
  return React.useMemo(
    () => (walletClient ? walletClientToSigner(walletClient) : provider),
    [provider, walletClient]
  );
}
