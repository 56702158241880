interface IAddresses {
  [key: number]: { [key: string]: string };
}

export const addresses: IAddresses = {
  81457: {
    IDO: "0x2363744800e95aB07b668A30fF86aFFad02fB11D",
    esDYNA: "0x0c17bBFd58F122e7045CD7F46FfC3ca15388624c",
    USDB: "0x4300000000000000000000000000000000000003",
  },
  168587773: {
    IDO: "0x7DAF325C34E06405C131CeAA77630EED1e69722B",
    USDB: "0x57A22572bCFa6cB0fdc7fe03b6E0E98cCEA1cDE5",
    esDYNA: "0x92bdc1bb3d653D7f25eD185f8e6b763B9F0f2e4D",
  },
};
