export enum SupportedChainId {
  // ARBITRUM_ONE = 42161,
  // ARBITRUM_GOERLI = 421613,

  // BSC_MAINNET = 56,
  // BST_TESTNET = 97,

  // ZK_MAINNET = 324,
  // ZK_TESTNET = 280,

  BLAST_MAINNET = 81457,
  BLAST_TESTNET = 168587773,
}

export const CHAIN_IDS_TO_NAMES = {
  // [SupportedChainId.ARBITRUM_ONE]: "arbitrum",
  // [SupportedChainId.ARBITRUM_GOERLI]: "arbitrum_goerli",
  // [SupportedChainId.BSC_MAINNET]: "Binance Smart Chain Mainnet",
  // [SupportedChainId.BST_TESTNET]: "Binance Smart Chain Testnet",
  // [SupportedChainId.ZK_MAINNET]: "zkSync Era Mainnet",
  // [SupportedChainId.ZK_TESTNET]: "zkSync Era Testnet",

  [SupportedChainId.BLAST_MAINNET]: "BLAST",
  [SupportedChainId.BLAST_TESTNET]: "BLAST Testnet",
};

/**
 * Array of all the supported chain IDs
 */
export const SUPPORTED_CHAIN_IDS: SupportedChainId[] = Object.values(
  SupportedChainId
).filter((id) => typeof id === "number") as SupportedChainId[];

export function isSupportedChain(
  chainId: number | null | undefined
): chainId is SupportedChainId {
  return !!chainId && !!SupportedChainId[chainId];
}

export const BLOCKEXPLOERER_URLS: {
  [chainId: number]: string;
} = {
  [SupportedChainId.BLAST_MAINNET]: "https://blastexplorer.io/",
  [SupportedChainId.BLAST_TESTNET]: "https://sepolia.blastscan.io/",
};
