import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

export const ConnectBtnBox = styled(Button)({
  height: "48px",
  maxHeight: "48px",
  width: "auto",
  color: "#B30D41",
  fontSize: "14px",
  fontFamily: "VCR-OSD-MONO",
  fontWeight: 700,
  textTransform: "initial",
  background: "rgba(255, 0, 229, 0.2)",
  border: "none",
  borderRadius: "4px",
  padding: "0 12px",
  "&:hover": {
    background: "rgba(255, 0, 229, 0.2)",
    color: "#B30D41",
  },
});
