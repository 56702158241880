import { Contract } from "@ethersproject/contracts";

import { UniswapInterfaceMulticall } from "@uniswap/redux-multicall/dist/abi/types";
import UniswapInterfaceMulticallJson from "@uniswap/v3-periphery/artifacts/contracts/lens/UniswapInterfaceMulticall.sol/UniswapInterfaceMulticall.json";

import IDO_ABI from "../abis/IDO.json";
import Pair_ABI from "../abis/Pair.json";
import { useMemo } from "react";
import { IDO, Pair } from "../abis/types";
import { isAddress } from "../utils";
import { addresses } from "../configs/constants";
import { chains } from "../configs/wagmiConfig";
import {
  AddressMap,
  AddressZero,
  MULTICALL_ADDRESS,
} from "../constants/address";
import { useEthersSigner, useWeb3 } from "./useWeb3";
import { useNetwork } from "wagmi";
import { SUPPORTED_CHAIN_IDS } from "src/constants/chains";
const { abi: MulticallABI } = UniswapInterfaceMulticallJson;

export function useContract<T extends Contract = Contract>(
  _address: AddressMap | string | undefined,
  ABI: any[]
): T | undefined {
  const { chainId, provider, account } = useWeb3();
  const signer = useEthersSigner();
  const address = useMemo(() => {
    if (!chainId) return;
    return typeof _address == "string"
      ? _address
      : _address
      ? _address[chainId]
      : undefined;
  }, [_address, chainId]);

  return useMemo(() => {
    if (!provider || !address) return;

    if (!isAddress(address) || address === AddressZero) {
      throw Error(`Invalid 'address' parameter '${address}'.`);
    }

    let contract;
    if (!account) {
      contract = new Contract(address, ABI, provider);
    } else {
      contract = new Contract(address, ABI, signer ? signer : provider);
    }
    return contract;
  }, [provider, address, ABI, account, signer]) as T;
}

export function useChainId() {
  const { chain } = useNetwork();
  if (chain) {
    if (SUPPORTED_CHAIN_IDS.indexOf(chain.id) == -1) return chains[0]?.id;
  }
  return chain ? chain?.id : chains[0]?.id;
}

export function useIDOContract() {
  const chainId = useChainId();
  return useContract<IDO>(addresses[chainId]?.IDO, IDO_ABI.abi);
}

export function useEsDYNAContract() {
  const chainId = useChainId();
  return useContract<Pair>(addresses[chainId]?.esDYNA, Pair_ABI.abi);
}

export function useUSDBContract() {
  const chainId = useChainId();
  return useContract<Pair>(addresses[chainId]?.USDB, Pair_ABI.abi);
}

export function useTokenContract(address?: string) {
  return useContract<Pair>(address, Pair_ABI.abi);
}

export function useInterfaceMulticall() {
  return useContract<UniswapInterfaceMulticall>(
    MULTICALL_ADDRESS,
    MulticallABI
  ) as UniswapInterfaceMulticall;
}
