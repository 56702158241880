import { connectorsForWallets, getDefaultWallets } from '@rainbow-me/rainbowkit';
import {
  coinbaseWallet,
  injectedWallet,
  metaMaskWallet,
  okxWallet,
  safeWallet,
  trustWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { configureChains, createConfig } from 'wagmi';
import { bsc, bscTestnet, zkSync, zkSyncTestnet } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';

const BLAST_MAIN = {
  id: 81457,
  network: 'BLAST ',
  name: 'BLAST ',
  nativeCurrency: {
    name: 'BLAST Ether',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: {
    infura: {
      http: ['https://blast.infura.io/v3'],
    },
    default: {
      http: ['https://rpc.blast.io'],
    },
    public: {
      http: ['https://rpc.blast.io'],
    },
  },
  testnet: true,
  hasIcon: true,
  iconUrl: 'https://raw.githubusercontent.com/metaverseblock/token-icon/main/168587773/BLAST.png',
}

const BLAST_TEST = {
  id: 168587773,
  network: 'BLAST Testnet',
  name: 'BLAST Testnet',
  nativeCurrency: {
    name: 'BLAST Ether',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: {
    infura: {
      http: ['https://blast-sepolia.infura.io/v3'],
    },
    default: {
      http: ['https://sepolia.blast.io'],
    },
    public: {
      http: ['https://sepolia.blast.io'],
    },
  },
  testnet: true,
  // hasIcon: true,
  // iconUrl: 'https://raw.githubusercontent.com/metaverseblock/token-icon/main/168587773/BLAST.png',
}

export const { chains, publicClient, webSocketPublicClient } = configureChains(
  [
    BLAST_MAIN,
    // BLAST_TEST
  ],
  [publicProvider()]
);

export const projectId = 'd9e02d8e4a4bb8fe574b63430fa1cf34';

const { wallets } = getDefaultWallets({
  appName: 'DYNA',
  projectId,
  chains,
});

const connectors = connectorsForWallets([
  ...wallets,
  {
    groupName: 'Other',
    wallets: [
      okxWallet({
        projectId,
        chains
      })
    ],
  },
]);

export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});

// import {
//   w3mConnectors,
//   w3mProvider,
// } from "@web3modal/ethereum";
// import { configureChains, createConfig } from "wagmi";
// import { mainnet, optimism, polygon, zkSync } from "wagmi/chains";

// // 1. Get projectID at https://cloud.walletconnect.com
// export const projectId = "d9e02d8e4a4bb8fe574b63430fa1cf34";

// // 2. Configure wagmi client
// export const chains = [zkSync];

// const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
// export const wagmiConfig = createConfig({
//   autoConnect: true,
//   connectors: w3mConnectors({ version: 1, chains, projectId }),
//   publicClient,
// });